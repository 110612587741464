import { lazy } from 'react';

import {
  UserLoginPermissions,
  RolesPermissions,
  ReportBuilderPermissions,
  AdminDashboardPermissions,
} from '../../Permissions';
import {
  LookupsPermissions,
  FormBuilderPermissions,
} from '../../Permissions/SystemParametersPermissions';
import LookupsLayout from '../../Layouts/Home/LookupsLayout/LookupsLayout';
import { FormBuilderLayout } from '../../Layouts/Home/FormBuilder/FormBuilderLayout';
import { RolesLayout } from '../../Layouts/Home/RolesLayout/RolesLayout';

import { ReportsBuilderLayout } from '../../Layouts/Home/ReportsBuilderLayout/ReportsBuilderLayout';

import { DashboardLayout } from '../../Layouts/Home/DashboardLayout/DashboardLayout';
import UsersLayout from '../../Layouts/Home/UsersLayout/UsersLayout.jsx';
import TalentPoolLayout from '../../Layouts/Home/TalentPoolLayout/TalentPoolLayout.jsx';
import RotationSchemaLayout from '../../Views/Home/RotationSchemaLayout/RotationSchemaLayout.jsx';

const Team = lazy(() =>
  import('../../Views/Home/Administration/TeamView/Team.View')
);
const UserView = lazy(() =>
  import('../../Views/Home/Administration/UsersView/User.View').then(
    (module) => ({ default: module.UserView })
  )
);
const ImportDetailsView = lazy(() =>
  import(
    '../../Views/Home/Common/ImportDetailsView/ImportDetails.View.jsx'
  ).then((module) => ({ default: module.ImportDetailsView }))
);

export const HomeRoutes = [
  {
    id: 31,
    path: '/Users/*',
    name: 'UsersView:UsersManagementView.edit-user',
    component: UsersLayout,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(UserLoginPermissions),
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.Users',
        isDisabled: false,
        route: '/home/Users',
        groupName: 'system-admin',
      },
      {
        name: 'UsersView:UsersManagementView.edit-user',
        isDisabled: false,
        route: '/home/Users/edit',
      },
    ],
  },
  {
    id: 30,
    path: '/Users',
    name: 'SideMenuView.Administration.Users',
    component: UserView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(UserLoginPermissions),
    groupId: 4,
    order: 1,
    icon: 'mdi mdi-badge-account-outline',
    iconActive: 'mdi mdi-badge-account',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.Users',
        isDisabled: false,
        route: '/home/Users',
        groupName: 'system-admin',
      },
    ],
  },
  {
    id: 33,
    path: '/Roles/*',
    name: 'SideMenuView.Administration.Roles',
    component: RolesLayout,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: Object.values(RolesPermissions),
    groupId: 4,
    order: 8,
    icon: 'mdi mdi-checkbox-multiple-marked',
    iconActive: 'mdi mdi-checkbox-multiple-marked c-primary',
    // routerLink: '/home/contacts',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [],
  },
  // {
  //   id: 32,
  //   path: '/Administration/Team',
  //   name: 'SideMenuView.Administration.Team',
  //   component: Team,
  //   layout: '/home',
  //   default: false,
  //   isRoute: true,
  //   authorize: true,
  //   roles: Object.values(TeamPermissions),
  //   groupId: 4,
  //   order: 3,
  //   icon: 'mdi mdi-account-outline c-white',
  //   iconActive: 'mdi mdi-account-outline c-blue-dark',
  //   isDisabled: false,
  //   showInMenu: true,
  //   isExact: false,
  //   breadcrumbs: [
  //     {
  //       name: 'SideMenuView.Administration.Team',
  //       isDisabled: false,
  //       route: '/home/Administration/Team',
  //       groupName: 'system-admin',
  //     },
  //   ],
  // },
  // {
  //   id: 37,
  //   path: '/FormBuilder/*',
  //   name: 'SideMenuView.FormBuilder',
  //   component: FormBuilderLayout,
  //   layout: '/home',
  //   default: false,
  //   isRoute: true,
  //   authorize: true,
  //   roles: [...Object.values(FormBuilderPermissions)],
  //   groupId: 16,
  //   order: 10,
  //   icon: 'mdi mdi-account-outline c-white',
  //   iconActive: 'mdi mdi-account-outline c-primary',
  //   isDisabled: false,
  //   showInMenu: true,
  //   isExact: false,
  //   breadcrumbs: [],
  // },
  {
    id: 90,
    path: '/import-details',
    name: 'ImportDetailsView:import-details',
    component: ImportDetailsView,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 2,
    order: 3,
    icon: 'mdi mdi-account-outline c-white',
    iconActive: 'mdi mdi-account-outline c-primary',
    isDisabled: false,
    showInMenu: false,
    isExact: false,
    breadcrumbs: [
      {
        name: 'ImportDetailsView:import-details',
        isDisabled: false,
        route: '/home/import-details',
      },
    ],
  },
  {
    id: 34,
    path: '/lookups/*',
    name: 'homeLayout.lookupsView.lookups',
    component: LookupsLayout,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [...Object.values(LookupsPermissions)],
    groupId: 16,
    order: 1,
    icon: 'mdi mdi-menu -multiple-marked',
    iconActive: 'mdi mdi-menu -multiple-marked c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'homeLayout.lookupsView.lookups',
        isDisabled: false,
        route: '/home/lookups',
        groupName: 'system-parameters',
      },
    ],
  },
  {
    id: 35,
    path: '/talent-pool/*',
    name: 'homeLayout.talent-pool-view.talent-pool',
    component: TalentPoolLayout,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 2,
    order: 1,
    icon: 'mdi  mdi-account-supervisor-circle-outline ',
    iconActive: 'mdi  mdi-account-supervisor-circle-outline c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    breadcrumbs: [
      {
        name: 'homeLayout.talent-pool-view.talent-pool',
        isDisabled: false,
        route: '/home/talentPool',
        groupName: 'candidates',
      },
    ],
  },
  {
    id: 47,
    path: '/dashboard/*',
    name: 'Dashboard:admin-dashboard',
    component: DashboardLayout,
    layout: '/home',
    default: true,
    isRoute: true,
    authorize: true,
    icon: 'mdi mdi-chart-bell-curve-cumulative c-white',
    iconActive: 'mdi mdi-chart-bell-curve-cumulative c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: false,
    roles: [...Object.values(AdminDashboardPermissions)],
    groupId: 1,
    order: 1,
    breadcrumbs: [
      {
        name: 'Dashboard:admin-dashboard',
        isDisabled: false,
        route: '/home/dashboard',
        groupName: 'dashboard',
      },
    ],
  },
  // keep this for power-bi config
  // {
  //   id: 176,
  //   path: '/userlogin-dashboard',
  //   name: 'Dashboard:userlogin-dashboard',
  //   component: UserDashboard,
  //   layout: '/home',
  //   default: false,
  //   isRoute: true,
  //   authorize: true,
  //   icon: 'mdi mdi-login-variant c-white',
  //   iconActive: 'mdi mdi-login-variant c-blue-dark',
  //   isDisabled: false,
  //   showInMenu: true,
  //   isExact: false,
  //   roles: [],
  //   groupId: 1,
  //   order: 2,
  //   breadcrumbs: [
  //     {
  //       name: 'Dashboard:userlogin-dashboard',
  //       isDisabled: true,
  //       route: '/home',
  //       groupName: 'dashboard',
  //     },
  //   ],
  // },
  // {
  //   id: 80,
  //   path: '/branches/*',
  //   name: 'BranchView:branches',
  //   component: BranchesLayout,
  //   layout: '/home',
  //   default: false,
  //   isRoute: true,
  //   authorize: true,
  //   roles: [...Object.values(BranchesPermissions)],
  //   groupId: 4,
  //   order: 2,
  //   icon: 'mdi mdi-badge-account-outline',
  //   iconActive: 'mdi mdi-badge-account',
  //   isDisabled: false,
  //   showInMenu: true,
  //   isExact: false,
  //   breadcrumbs: [
  //     {
  //       name: 'Branches.Branches:branches',
  //       isDisabled: false,
  //       route: '/home/branches',
  //       groupName: 'system-admin',
  //     },
  //   ],
  // },
  // {
  //   id: 86,
  //   path: '/Reports-Builder/*',
  //   name: 'ReportsBuilderg:reportsbuilder',
  //   component: ReportsBuilderLayout,
  //   layout: '/home',
  //   default: false,
  //   icon: 'mdi mdi-chart-box-plus-outline c-white',
  //   iconActive: 'mdi mdi-chart-box-plus-outline c-primary',
  //   isDisabled: false,
  //   showInMenu: true,
  //   isExact: false,
  //   isRoute: true,
  //   authorize: true,
  //   roles: [...Object.values(ReportBuilderPermissions)],
  //   groupId: 23,
  //   order: 2,
  //   breadcrumbs: [
  //     {
  //       name: 'ReportsBuilderg:reportsbuilder',
  //       isDisabled: false,
  //       route: '/home/country',
  //       groupName: 'Reports',
  //     },
  //   ],
  // },
  {
    id: 209,
    path: '/rotation-criteria/*',
    name: 'homeLayout.RotationCriteriaView.rotation-criteria',
    component: RotationSchemaLayout,
    layout: '/home',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 5,
    order: 1,
    icon: 'mdi mdi-menu mdi-receipt c-white',
    iconActive: 'mdi mdi-menu mdi-receipt  c-primary',
    isDisabled: false,
    showInMenu: true,
    breadcrumbs: [
      {
        name: 'homeLayout.RotationCriteriaView.rotation-criteria',
        isDisabled: true,
        route: '/home/RotationSchema',
        groupName: 'rotation-schema',
      },
    ],
  },
  // {
  //   id: 307,
  //   path: '/billing-users/*',
  //   name: 'billing-users',
  //   component: UsersManagementLayout,
  //   layout: '/home',
  //   default: true,
  //   isRoute: true,
  //   authorize: true,
  //   roles: [],
  //   isDisabled: false,
  //   showInMenu: false,
  //   isExact: true,
  //   breadcrumbs: [
  //     {
  //       name: 'Billing',
  //       isDisabled: false,
  //       route: '/home/billing',
  //     },
  //     {
  //       name: 'Users',
  //       isDisabled: true,
  //       route: '/home/users',
  //     },
  //   ],
  // },
];
