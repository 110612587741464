import React, { memo, useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import { useTranslation } from 'react-i18next';
import {
  getDownloadableLink,
  languageChange,
  showError,
  showSuccess,
  GlobalNavigate,
  returnPropsByPermissions,
} from '../../../../Helper';
import {
  XeleCollapse,
  XeleDialog,
  Spinner,
  PermissionsComponent,
  LoadableImage,
} from '../../../../Components';
import { UPDATE, LOGOUt } from '../../../../store/login/Actions';
import { ChangePasswordView } from '../../../Account';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import {
  FreshdeskHelper,
  FreshdeskHelperRemove,
} from '../../../../Helper/Freshdesk.Helper';
import { UserLoginPermissions } from '../../../../Permissions';

import { GlobalOrderFilterActions } from '../../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import {
  SET_SELECTED_THEME,
  TOGGLE_THEME,
} from '../../../../store/theme/Actions';
import {
  OrganizationUserSearch,
  UpdateMyProfileImage,
} from '../../../../Services/UsersServices';
import { uploadFile } from '../../../../Services/File';
import { LoadableImageEnum } from '../../../../Enums';

const FirstLettersExp = /\b(\w)/gm;

const translationLocation = 'userMenuView.';

export const UserMenuView = memo(({ logout }) => {
  const { t } = useTranslation('HeaderView');
  const dispatch = useDispatch();
  const [openHelp, setOpenHelp] = useState(false);
  const [HardReloadVal, setHardReloadVal] = useState(false);
  const [imageReq, setImageReq] = useState(null);
  const [languageToggler, setLanguageToggler] = useState(false);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [isLoading, setIsLoading] = useState(false);
  const [ChangePasswordDialog, setChangePasswordDialog] = useState(false);
  const [isOpenDialogReload, setIsOpenDialogReload] = useState(false);
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const isDarkMode = useSelector((store) => store.theme.isDarkMode);

  const uploaderRef = useRef(null);

  const [languages] = useState([
    {
      key: 'en',
      value: `${translationLocation}english`,
      icon: 'i-_United_States',
    },
    {
      key: 'ar',
      value: `${translationLocation}arabic`,
      icon: 'i-United_Arab',
    },
    {
      key: 'ru',
      value: `${translationLocation}Russian`,
      icon: 'i-Flag_of_Russia',
    },
    {
      key: 'cn',
      value: `${translationLocation}Chinese`,
      icon: 'i-Flag_of_theChina',
    },
  ]);

  useEffect(() => {
    if (loginResponse) setImageReq(loginResponse);
  }, [loginResponse]);

  const languageClicked = useCallback(languageChange);

  const openHelpHandler = () => {
    setOpenHelp(!openHelp);
    setHardReloadVal(!HardReloadVal);
    if (openHelp) FreshdeskHelperRemove();
    else FreshdeskHelper();
  };

  const openHelpHandlerDialogReload = () => {
    if (HardReloadVal) setIsOpenDialogReload(true);
    else setIsOpenDialogReload(false);
  };



  const HardReload = () => {
    localStorage.removeItem('session');
    window.localStorage.clear();
    dispatch(LOGOUt());
    setTimeout(() => {
      GlobalNavigate('/account/login');
    }, 100);
    window.location.reload();
  };

  const languageTogglerClicked = () => {
    setLanguageToggler(!languageToggler);
  };

  const handleToggleTheme = () => {
    dispatch(TOGGLE_THEME());
    dispatch(SET_SELECTED_THEME({ isDarkMode: !isDarkMode }));
  };

  const ChangePasswordClicked = () => {
    setChangePasswordDialog(true);
  };
  const getOrganizationUserSearch = useCallback(async () => {
    setIsLoading(true);
    const response = await OrganizationUserSearch({
      userName: loginResponse.userName,
      pageIndex: 0,
      pageSize: 25,
    });
    if (response && response.result) {
      setIsLoading(false);
      dispatch(
        ActiveItemActions.activeItemRequest(
          response.result.find((item) => item.id === loginResponse.userId)
        )
      );
      localStorage.setItem(
        'activeUserItem',
        JSON.stringify(
          response.result.find((item) => item.id === loginResponse.userId)
        )
      );
      if (window.location.pathname.includes('home/Users/edit'))
        window.location.href = `/home/Users/edit?id=${loginResponse.userId}`;
      else GlobalNavigate(`/home/Users/edit?id=${loginResponse.userId}`);
    } else setIsLoading(false);
  }, [dispatch, loginResponse?.userId, loginResponse?.userName]);
  const editProfileHandler = () => {
    getOrganizationUserSearch();
  };
  const fileChanged = useCallback(
    async (event) => {
      if (!event.target.value) return;
      setIsLoading(true);
      const response = await uploadFile({ file: event.target.files[0] });
      if (response) {
        const profileImageRes = await UpdateMyProfileImage(response.uuid);
        if (profileImageRes) {
          if (
            JSON.parse(localStorage.getItem('session')).userId ===
            profileImageRes.id
          ) {
            const updatedState = JSON.parse(localStorage.getItem('session'));
            const update = {
              ...updatedState,
              profileImg: profileImageRes.profileImg,
            };
            localStorage.setItem('session', JSON.stringify(update));
            dispatch(UPDATE(update));
          }
          if (
            localStorage.getItem('activeUserItem') &&
            JSON.parse(localStorage.getItem('activeUserItem')).id ===
              profileImageRes.id
          ) {
            const updateActiveUser = JSON.parse(
              localStorage.getItem('activeUserItem')
            );
            localStorage.setItem(
              'activeUserItem',
              JSON.stringify({
                ...updateActiveUser,
                profileImg: profileImageRes.profileImg,
              })
            );
            if (window.location.pathname.includes('home/Users/edit'))
              window.location.href = `/home/Users/edit?id=${profileImageRes.id}`;
          }
          showSuccess(t(`${translationLocation}image-changed-successfully`));
        } else showError(t(`${translationLocation}save-image-failed`));
        setIsLoading(false);
      } else {
        showError(t(`${translationLocation}upload-image-failed`));
        setIsLoading(false);
      }
    },
    [dispatch, t]
  );
  return (
    <div className='cards'>
      <div className='card-content user-menu-wrapper'>
        <Spinner isActive={isLoading} isAbsolute />
        <div className='d-flex-column-center'>
          <div className='p-relative'>
            {loginResponse &&
              loginResponse.fullName &&
              (!imageReq || !imageReq.profileImg) && (
                <Avatar className='avatars-wrapper mb-3'>
                  {loginResponse.fullName.match(FirstLettersExp).join('')}
                </Avatar>
              )}
            {imageReq && imageReq.profileImg && (
              <LoadableImage
                src={getDownloadableLink(imageReq.profileImg)}
                type={LoadableImageEnum.div.key}
                alt={t('user-image')}
                classes='user-img'
              />
            )}
            <input
              type='file'
              className='d-none'
              onChange={fileChanged}
              accept='image/*'
              ref={uploaderRef}
            />
            <ButtonBase className='btns-icon theme-outline-dark btns-small mx-0 user-btn'>
              <span
                className='mdi mdi-camera'
                onClick={() => uploaderRef.current.click()}
              />
            </ButtonBase>
          </div>
          <p className='c-primary'>{loginResponse && loginResponse.fullName}</p>
          <PermissionsComponent
            permissionsList={Object.values(UserLoginPermissions)}
            permissionsId={
              UserLoginPermissions.EditButtonInUserRecord.permissionsId
            }
          >
            <ButtonBase
              className='btns theme-solid mb-2'
              onClick={editProfileHandler}
            >
              {' '}
              <span className=' mdi mdi-account-edit-outline px-1' />{' '}
              {t(`${translationLocation}edit-profile`)}
            </ButtonBase>
          </PermissionsComponent>
          <div className='separator-h' />
          <ButtonBase className='btns theme-menu' onClick={openHelpHandler}>
            <span
              className={
                openHelp !== true
                  ? 'mdi mdi-help-network'
                  : 'mdi mdi-timeline-help-outline'
              }
            />{' '}
            <div className='mx-3 d-flex-v-center-h-between '>
              <span>
                {openHelp !== true
                  ? t(`${translationLocation}open-help-support`)
                  : t(`${translationLocation}close-help-support`)}{' '}
              </span>
              {openHelp === false ? (
                <span className='mdi mdi-chevron-down' />
              ) : (
                <span className='mdi mdi-chevron-up' />
              )}
            </div>
          </ButtonBase>
          {HardReloadVal && (
            <ButtonBase
              className='pl-3 btns theme-menu'
              onClick={openHelpHandlerDialogReload}
            >
              <span className='mdi mdi-refresh' />{' '}
              <span className='mx-3'>
                {t(`${translationLocation}hard-refresh-and-clear-caches`)}
              </span>
            </ButtonBase>
          )}
          <div className='separator-h' />
          <div className='separator-h' />
          {returnPropsByPermissions([]) && (
            <ButtonBase
              className='btns theme-menu'
              onClick={() => {
                const activityCallCenter = Math.random();
                localStorage.setItem('activityCallCenter', activityCallCenter);
                GlobalNavigate(
                  `/home/Activities?user=${loginResponse.userName}`
                );
                dispatch(
                  GlobalOrderFilterActions.globalOrderFilterRequest({
                    ...orderFilter,
                    activitesCallCenterFilter: {
                      ...orderFilter.activitesCallCenterFilter,
                      agent:
                        (loginResponse && {
                          id: loginResponse && loginResponse.userId,
                          fullName: loginResponse && loginResponse.fullName,
                        }) ||
                        null,
                    },
                  })
                );
              }}
            >
              <span className='icons i-activities' />{' '}
              <span className='mx-3'>
                {t(`${translationLocation}my-activities`)}
              </span>
            </ButtonBase>
          )}
          <div className='separator-h' />
          <ButtonBase
            className='btns theme-menu'
            onClick={ChangePasswordClicked}
          >
            <span className='mdi mdi-lock' />{' '}
            <span className='mx-3'>
              {t(`${translationLocation}change-password`)}
            </span>
          </ButtonBase>
          <div className='separator-h' />
          <div className='separator-h' />
          <ButtonBase
            className='btns theme-menu'
            onClick={languageTogglerClicked}
          >
            <div className='mdi mdi-translate' />{' '}
            <div className='mx-3 d-flex-v-center-h-between '>
              {t(`${translationLocation}language`)}
              {languageToggler === false ? (
                <span className='mdi mdi-chevron-down' />
              ) : (
                <span className='mdi mdi-chevron-up' />
              )}
            </div>
          </ButtonBase>
          <div className='separator-h mb-2' />
          <XeleCollapse
            isOpen={languageToggler}
            classes='w-100 px-3'
            component={
              <>
                {languages.map((item, index) => (
                  <React.Fragment key={`languages${item.key}`}>
                    <ButtonBase
                      className='btns theme-menu'
                      onClick={() => languageClicked(item.key)}
                    >
                      <span className={`icon-falg-wrpper  ${item.icon} mx-3`} />
                      {t(item.value)}
                    </ButtonBase>
                    <span
                      className={`separator-h${
                        index === languages.length - 1 ? ' mb-2' : ''
                      }`}
                    />
                  </React.Fragment>
                ))}
              </>
            }
          />
        </div>
      </div>
      <div className='card-footer'>
        <ButtonBase
          className='btns theme-outline mb-2 logout-footer'
          onClick={logout}
        >
          <span>{t(`${translationLocation}logout`)}</span>
        </ButtonBase>
      </div>
      <XeleDialog
        isOpen={ChangePasswordDialog}
        onCloseClicked={() => setChangePasswordDialog(false)}
        titleText={t(`${translationLocation}change-password`)}
        maxWidth='sm'
        dialogContent={
          <>
            <ChangePasswordView
              onCancelClicked={() => setChangePasswordDialog(false)}
              translationPath={translationLocation}
            />
          </>
        }
      />
      {isOpenDialogReload && (
        <XeleDialog
          titleText={`${t(`${translationLocation}confirm-message`)}`}
          saveText={`${t(`${translationLocation}confirm`)}`}
          saveType='button'
          maxWidth='sm'
          dialogContent={
            <div className='d-flex-column-center'>
              <Spinner isActive={isLoading} isAbsolute />
              <span className='mdi mdi-help-rhombus-outline c-primary mdi-48px' />
              <span
                className='pt-3'
                style={{ textAlignVertical: 'center', textAlign: 'center' }}
              >{`${t(`${translationLocation}Relode-description`)}`}</span>
            </div>
          }
          saveClasses='btns theme-solid c-info: w-100 mx-2 mb-2'
          isOpen={isOpenDialogReload}
          onSaveClicked={HardReload}
          onCloseClicked={() => setIsOpenDialogReload(false)}
          onCancelClicked={() => setIsOpenDialogReload(false)}
        />
      )}
    </div>
  );
});
UserMenuView.propTypes = {
  logout: PropTypes.func.isRequired,
};
