import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { ActionsButtonsEnum } from '../../../Enums';
import { XeleSelect } from '../Select/XeleSelect';
import { PermissionsComponent } from '../..';

const translationPath = 'Shared:actions-buttons';
export const ActionsButtonsComponent = ({
  onActionButtonChanged,
  onActionsButtonClicked,
  onFormTypeSelectChanged,
  withType,
  typeData = [],
  isDisabled,
  wrapperClasses = '',
  permissionsList,
  addPermissionsId,
  selectPermissionsId,
  checkDisable,
  withText = '',
  closeAction,
  enableToSendToRotation,
  assignmentAction = '',
  selectedAction,
}) => {
  const { t } = useTranslation('Shared', {
    keyPrefix: translationPath,
  });
  const [activeAction, setActiveAction] = useState(
    () => ActionsButtonsEnum[1]
  );

  const [actionData, setActionData] = useState([]);

  const onSelectChanged = useCallback(
    (selectedValue) => {
      const selectedObject = actionData.find((action) => action.id === selectedValue);
      setActiveAction(selectedObject);
      if (onActionButtonChanged) onActionButtonChanged(selectedObject);
    },
    [actionData, onActionButtonChanged, setActiveAction]
  );
  
  const getActionData = useCallback(() => {
    const actions = [ActionsButtonsEnum[1]];
    if (enableToSendToRotation) actions.push(ActionsButtonsEnum[8]);
    setActionData(actions);
  }, [enableToSendToRotation]);

  useEffect(() => {
    getActionData();
  }, [getActionData]);

  useEffect(() => {
    if (closeAction) {
      setActiveAction(ActionsButtonsEnum[1]);
      if (onActionButtonChanged)
        onActionButtonChanged(ActionsButtonsEnum[1].id);
    }
  }, [closeAction]);

  useEffect(() => {
    if (assignmentAction && !selectedAction)
      setActiveAction(ActionsButtonsEnum[11]);
  }, [assignmentAction, onActionButtonChanged]);

  return (
    <div className={`actions-buttons-wrapper ${wrapperClasses}`}>
      <div className='d-inline-flex'>
        {(!withType || activeAction.id !== ActionsButtonsEnum[1].id) && (
          <PermissionsComponent
            permissionsList={permissionsList}
            permissionsId={selectPermissionsId}
            allowEmptyRoles
          >
            <Button
              disabled={
                isDisabled || (checkDisable && checkDisable(activeAction?.id))
              }
              type='button'
              id={`${t(activeAction?.buttonLabel)}_ref`}
              onClick={() => onActionsButtonClicked(activeAction?.id)}
              className={`btns theme-solid ${activeAction?.classes}`}
            >
              <span>{t(activeAction?.buttonLabel)}</span>
            </Button>
          </PermissionsComponent>
        )}
        {withType && activeAction?.id === ActionsButtonsEnum[1]?.id && (
          <PermissionsComponent
            permissionsList={permissionsList}
            permissionsId={addPermissionsId}
            allowEmptyRoles
          >
            <XeleSelect
              data={typeData}
              defaultValue={-1}
              emptyItem={{
                value: -1,
                text: withText || 'add',
                isHiddenOnOpen: true,
              }}
              valueInput='id'
              translationPath={translationPath}
              onSelectChanged={onFormTypeSelectChanged}
              wrapperClasses='bg-secondary c-white mx-2'
              themeClass='theme-action-buttons'
              idRef='AddActionsRef'
              keyValue='actionsbuttons'
              keyLoopBy='id'
              textInput='name'
            />
          </PermissionsComponent>
        )}
      </div>
      {onActionButtonChanged && (
        <>
          <div className='d-inline-flex'>
            <PermissionsComponent
              permissionsList={permissionsList}
              permissionsId={selectPermissionsId}
              allowEmptyRoles
            >
              {!closeAction && enableToSendToRotation && (
                <XeleSelect
                  data={actionData}
                  value={activeAction.id}
                  valueInput='id'
                  defaultValue={ActionsButtonsEnum[1].id}
                  themeClass='theme-action-buttons'
                  idRef='ActionsRef'
                  keyValue='actionsbuttons'
                  keyLoopBy='id'
                  textInput='label'
                  translationPath={translationPath}
                  onSelectChanged={onSelectChanged}
                />
              )}
              {closeAction && enableToSendToRotation && (
                <XeleSelect
                  data={actionData}
                  value={ActionsButtonsEnum[1]}
                  defaultValue={ActionsButtonsEnum[1]}
                  onSelectChanged={onSelectChanged}
                  themeClass='theme-action-buttons'
                  idRef='AllActionsRef'
                  menuClasses='action-select'
                  keyValue='actionsbuttons'
                  keyLoopBy='id'
                  textInput='label'
                  translationPath={translationPath}
                />
              )}
            </PermissionsComponent>
          </div>
        </>
      )}
    </div>
  );
};
ActionsButtonsComponent.propTypes = {
  onActionButtonChanged: PropTypes.func,
  onFormTypeSelectChanged: PropTypes.func,
  onActionsButtonClicked: PropTypes.func,
  wrapperClasses: PropTypes.string,
  withType: PropTypes.bool,
  typeData: PropTypes.instanceOf(Array),
  isDisabled: PropTypes.bool,
  enableMerge: PropTypes.bool,
  enableBulk: PropTypes.bool,
  assign: PropTypes.bool,
  unAssign: PropTypes.bool,
  enableImport: PropTypes.bool,
  permissionsList: PropTypes.instanceOf(Array),
  addPermissionsId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(String),
  ]),
  selectPermissionsId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(String),
  ]),
  checkDisable: PropTypes.func,
  withText: PropTypes.string,
  assignmentAction: PropTypes.string,
  enableCloseLeads: PropTypes.bool,
  enableQualifyLead: PropTypes.bool,
  isSelectAllFromTable: PropTypes.bool,
  enablereassignOwnerLeads: PropTypes.bool,
  enablereassignSeekerLeads: PropTypes.bool,
  value: PropTypes.string,
  enableToSendToRotation: PropTypes.bool,
  enableSendToOwnerRotation: PropTypes.bool,
  pullToContactOpportunity: PropTypes.bool,
};
