export const TableActions = {
  add: {
    key: 'add', // must be unique
    icon: 'table-action-icon mdi mdi-plus',
    buttonClasses: 'table-action-btn btns-icon theme-solid bg-green',
  },
  edit: {
    key: 'edit', // must be unique
    icon: 'table-action-icon  mdi mdi-lead-pencil',
    buttonClasses: 'table-action-btn  btns-icon theme-solid bg-secondary',
  },
  view: {
    key: 'view', // must be unique
    icon: 'table-action-icon mdi mdi-eye-outline',
    buttonClasses: 'table-action-btn btns-icon theme-solid bg-green',
  },
  viewSchemaTabelView: {
    key: 'viewText', // must be unique
    icon: 'table-action-icon mdi mdi-eye-outline bg-green',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:view',
  },
  preview: {
    key: 'preview', // must be unique
    icon: 'mdi mdi-eye-outline',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:preview',
  },
  delete: {
    key: 'delete', // must be unique
    icon: 'table-action-icon mdi mdi-trash-can',
    buttonClasses: 'table-action-btn btns-icon theme-solid bg-danger',
  },
  check: {
    key: 'check',
    icon: 'table-action-icon mdi mdi-check ',
    buttonClasses: 'table-action-btn btns-icon theme-solid bg-green',
  },
  close: {
    key: 'close',
    icon: 'table-action-icon mdi mdi-close',
    buttonClasses: 'table-action-btn btns-icon theme-solid bg-secondary',
  },
  phone: {
    key: 'phone',
    icon: 'table-action-icon mdi mdi-phone',
    buttonClasses: 'table-action-btn btns-icon theme-action active bg-phone',
  },
  email: {
    key: 'email',
    icon: 'table-action-icon icons i-email',
    buttonClasses: 'table-action-btn btns-icon theme-action active bg-email',
  },
  whatsapp: {
    key: 'whatsapp',
    icon: 'table-action-icon mdi mdi-whatsapp',
    buttonClasses: 'table-action-btn btns-icon theme-action active bg-whatsapp',
  },
  account: {
    key: 'account',
    icon: 'table-action-icon icons i-account',
    buttonClasses: 'table-action-btn btns-icon theme-action active bg-account',
  },
  dotsHorizontal: {
    key: 'dotsHorizontal',
    icon: 'table-action-icon mdi mdi-dots-horizontal',
    buttonClasses: 'table-action-btn btns-icon c-white bg-secondary',
  },
  phoneSolid: {
    key: 'phoneSolid',
    icon: 'table-action-icon mdi mdi-phone',
    buttonClasses: 'table-action-btn btns-icon c-white bg-primary',
  },
  emailSolid: {
    key: 'emailSolid',
    icon: 'table-action-icon icons i-email',
    buttonClasses: 'table-action-btn btns-icon bg-primary',
  },
  openFile: {
    key: 'openFile',
    icon: 'table-action-icon mdi mdi-folder-open-outline c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:tables.open-file',
  },
  runFile: {
    key: 'runFile',
    icon: 'table-action-icon mdi mdi-folder-open-outline c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:tables.run',
  },
  viewDetails: {
    key: 'viewDetails',
    icon: 'table-action-icon mdi mdi-eye-outline c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:tables.view-details',
  },
  deleteText: {
    key: 'deleteText',
    icon: 'table-action-icon mdi mdi-trash-can c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:delete',
  },
  editText: {
    key: 'editText',
    icon: 'table-action-icon mdi mdi-lead-pencil c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:edit',
  },
  approvedText: {
    key: 'approvedText',
    icon: 'table-action-icon mdi mdi-thumb-up-outline c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:approved',
  },
  approvedCheck: {
    key: 'approvedCheck',
    icon: 'table-action-icon mdi mdi-check c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:approved',
  },
  rejectedText: {
    key: 'rejectedText',
    icon: 'table-action-icon mdi mdi-close c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:rejected',
  },
  downloadText: {
    key: 'downloadText',
    icon: 'table-action-icon mdi mdi-download-outline c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:download',
  },
  addPrimary: {
    key: 'addPrimary', // must be unique
    icon: 'table-action-icon mdi mdi-plus',
    buttonClasses: 'table-action-btn btns-icon theme-solid bg-primary',
  },
  print: {
    key: 'print', // must be unique
    icon: 'table-action-icon mdi mdi-cloud-print-outline',
    buttonClasses: 'table-action-btn btns-icon theme-solid bg-primary',
  },
  transaction: {
    key: 'transaction', // must be unique
    icon: 'table-action-icon mdi mdi-currency-usd',
    buttonClasses: 'table-action-btn btns-icon theme-solid bg-primary',
  },
  // buttonWithText: {
  //   key: 'buttonWithText',
  //   buttonClasses: 'btns',
  // },
  // textOnly: {
  //   key: 'textOnly',
  // },
  externalComponent: {
    key: 'externalComponent',
  },
  smsSolid: {
    key: 'smsSolid',
    icon: 'table-action-icon mdi mdi-cellphone',
    buttonClasses: 'table-action-btn btns-icon theme-solid',
  },
  whatsappSolid: {
    key: 'whatsappSolid', // must be unique
    icon: 'table-action-icon mdi mdi-whatsapp',
    buttonClasses: 'table-action-btn btns-icon theme-solid bg-green-dark',
  },
  virtualTour: {
    key: 'virtualTour',
    icon: 'table-action-icon mdi mdi-eye-outline c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:virtual-tour',
  },
  manageAgents: {
    key: 'manageRecruiters',
    icon: 'table-action-icon mdi mdi-account-multiple-outline  c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:manage-recruiters',
  },
  editIconAndLabel: {
    key: 'editIconAndLabel',
    icon: 'table-action-icon mdi mdi-lead-pencil bg-primary',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:edit',
  },
  deleteIconAndLabel: {
    key: 'deleteIconAndLabel',
    icon: 'table-action-icon mdi mdi-trash-can bg-danger',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:delete',
  },
  replyText: {
    key: 'replyText',
    icon: 'table-action-icon mdi mdi-reply c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:reply',
  },

  addPrimaryText: {
    key: 'addPrimaryText', // must be unique
    icon: 'table-action-icon mdi mdi-plus c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:addReceipt',
  },
  printText: {
    key: 'printText', // must be unique
    icon: 'table-action-icon mdi mdi-cloud-print c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:print',
  },
  transactionText: {
    key: 'transactionText', // must be unique
    icon: 'table-action-icon mdi mdi-currency-usd c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:transaction',
  },
  reassignAgent: {
    key: 'reassign-agent', // must be unique
    icon: 'table-action-icon mdi mdi-account-switch c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:reassign-agent',
  },
  editReminderText: {
    key: 'editReminderText',
    icon: 'table-action-icon  mdi mdi-table-edit c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:edit-reminder',
  },
  addReminderText: {
    key: 'addReminderText',
    icon: 'table-action-icon  mdi mdi-table-column-width c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:add-reminder',
  },
  discardDuplicateText: {
    key: 'discardDuplicateText',
    icon: 'table-action-icon  mdi mdi-playlist-remove c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal ',
    label: 'Shared:discard-duplicate',
  },
  removeDiscardText: {
    key: 'removeDiscardText',
    icon: 'table-action-icon  mdi mdi-playlist-check c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal ',
    label: 'Shared:remove-discard',
  },
  PrintReceipt: {
    key: 'PrintReceiptText', // must be unique
    icon: 'table-action-icon mdi mdi-cloud-print c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    // label: 'Shared:PrintReceipt',
  },
  PrintInvoice: {
    key: 'PrintInvoiceText', // must be unique
    icon: 'table-action-icon mdi mdi-cloud-print c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    // label: 'Shared:PrintInvoice',
  },
  RunText: {
    key: 'RunText',
    icon: 'table-action-icon  mdi mdi-webhook c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal ',
    label: 'Shared:Run',
  },
  openLinkText: {
    key: 'openLinkText',
    icon: 'table-action-icon  mdi mdi-open-in-new c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:open-document-link',
  },
  copyItem: {
    key: 'copyItem',
    icon: 'table-action-icon  mdi mdi-content-copy',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
  },
  viewProcess: {
    key: 'viewProcess',
    icon: 'table-action-icon  mdi mdi-eye-settings',
    label: 'Shared:view-process',
    labelClasses: 'px-1 c-white fw-normal',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0 c-white',
  },
  addActivity: {
    key: 'addActivity',
    icon: 'table-action-icon mdi mdi-plus c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:tables.addActivity',
  },
  addPrimaryText: {
    key: 'addPrimaryText', // must be unique
    icon: 'table-action-icon mdi mdi-plus c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:addReceipt',
  },
  viewHistory: {
    key: 'viewHistory',
    icon: 'table-action-icon  mdi mdi-history',
    buttonClasses: 'table-action-btn  btns-icon theme-solid bg-secondary',
  },

  share: {
    key: 'share',
    icon: 'table-action-icon mdi mdi-share c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:tables.share',
  },
  angryBird: {
    key: 'angry-bird',
    icon: 'table-action-icon mdi mdi-account-switch c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:Angry-Bird',
  },
  users: {
    key: 'users',
    icon: 'table-action-icon mdi mdi-account-group-outline c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    // label: 'Shared:Angry-Bird',
  },
  apiDetails: {
    key: 'apiDetails',
    icon: 'table-action-icon mdi mdi-eye-outline c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:tables.api-details',
  },
  dashboardstable: {
    key: 'dashboardstable',
    icon: 'table-action-icon mdi mdi-chart-pie c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:dashboard',
  },
  viewDetails: {
    key: 'viewDetails',
    icon: 'table-action-icon mdi mdi-eye-outline c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:tables.view-Approvals',
  },
  viewInstallments: {
    key: 'installments',
    icon: 'table-action-icon mdi mdi-cash-multiple c-white',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0',
    labelClasses: 'px-1 c-white fw-normal',
    label: 'Shared:tables.installments',
  },
  viewAgents: {
    key: 'Agents',
    icon: 'table-action-icon  mdi mdi-eye-settings',
    label: 'Shared:tables.view-agents',
    labelClasses: 'px-1 c-white fw-normal',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0 c-white',
  },
  AI: {
    key: 'AI',
    icon: 'table-action-icon  mdi mdi mdi-creation',
    label: 'Shared:tables.agent-calls',
    labelClasses: 'px-1 c-white fw-normal',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0 c-white',
  },
  playAudio:{
    key: 'playAudio',
    icon: 'table-action-icon mdi mdi-play',
    labelClasses: 'px-1 c-white fw-normal',
    buttonClasses: 'table-action-btn btns theme-transparent miw-0 c-white',
  }
};
